import type {
    ZodRawShape,
    ZodObject,
    ZodOptional,
    ZodNullable,
    ZodDefault,
    ZodSchema,
    input,
    ZodBoolean
} from 'zod'
import type {
    BaseFormFieldObject,
    FormFieldObject,
    BaseZodSchemaFromNestedType,
    NestedZodObjectOrEffects,
    NestedZodTypeInDefaultOrOptional,
    PrimitiveTypeFromZodType,
    SupportedZodTypes,
    FormDataObject
} from '../../types/form'
import type { ModelRef } from 'vue'

/**
 * A function that returns the base Zod schema from a schema nested in ZodEffects.
 * @param schema The schema to get the base Zod schema from.
 */
function getBaseZodSchema<T extends ZodSchema>(schema: NestedZodObjectOrEffects<T>): BaseZodSchemaFromNestedType<T> | null {
    let baseSchema = schema

    while (baseSchema?._def?.typeName === 'ZodEffects' && baseSchema._def.schema) {
        baseSchema = baseSchema._def.schema as NestedZodObjectOrEffects<T>
    }

    // TODO: fix type
    return baseSchema._def.typeName === 'ZodObject' ? (baseSchema as ZodObject<ZodRawShape>).shape as unknown as BaseZodSchemaFromNestedType<T> : null
}

const isZodOptional = <T extends ZodOptional<any>>(type: SupportedZodTypes): type is T => type?._def?.typeName === 'ZodOptional'
const isZodDefault = <T extends ZodDefault<any>>(type: SupportedZodTypes): type is T => type?._def?.typeName === 'ZodDefault'
const isZodObject = <T extends ZodObject<any>>(type: SupportedZodTypes): type is T => type?._def?.typeName === 'ZodObject'
const isZodNullable = <T extends ZodNullable<any>>(type: SupportedZodTypes): type is T => type?._def?.typeName === 'ZodNullable'

const isZodBoolean = (type: SupportedZodTypes): type is ZodBoolean => type._def.typeName === 'ZodBoolean'

/**
 * A function that builds the base of the form field object from a Zod type.
 * The Zod type can be wrapped in various wrapper types like `ZodOptional`, `ZodDefault`, `ZodNullable`.
 * @example
 * const result = buildFormFieldObjectBase(z.string().optional().default('foo'))
 * // result: { __r: false, __v: 'foo' }
 * @param type The Zod type to build the form field object from.
 */
function buildFormFieldObjectBase<T extends SupportedZodTypes>(
    type: NestedZodTypeInDefaultOrOptional<T>
): BaseFormFieldObject<PrimitiveTypeFromZodType<T>>
{
    function _buildFormFieldObjectBase(
        type: T,
        required: ReturnType<typeof buildFormFieldObjectBase>['__r'] | undefined,
        val: ReturnType<typeof buildFormFieldObjectBase>['__v'] | undefined
    ): ReturnType<typeof buildFormFieldObjectBase> {
        if (isZodOptional(type)) {
            return _buildFormFieldObjectBase(type._def.innerType, required ?? false, val)
        }

        if (isZodDefault(type)) {
            return _buildFormFieldObjectBase(type._def.innerType, required, type._def.defaultValue())
        }

        if (isZodNullable(type)) {
            return _buildFormFieldObjectBase(type._def.innerType, required, val)
        }

        return {
            __r: required ?? true,
            __v: isZodBoolean(type) ? val ?? false : val,
        }
    }

    return _buildFormFieldObjectBase(type, undefined, undefined) as FormFieldObject<PrimitiveTypeFromZodType<T>>
}

interface GenerateFormDataObjectsOptions {
    /**
     * Whether to convert null values to undefined.
     * @default false
     */
    convertNullToUndefined: boolean
}

/**
 * A function that generates a form data object from a Zod schema.
 * The form data object is a custom object that is used internally by a custom form component.
 * @param schema The Zod schema to generate the form data object from.
 * @param values The initial values for the form data object.
 * @param options The options for generating the form data object.
 * @throws Error If the schema could not be normalized.
 */
export function generateFormDataObjects<T extends ZodSchema>(schema: NestedZodObjectOrEffects<T> | T, values?: Partial<input<T>>, options?: Partial<GenerateFormDataObjectsOptions>): [FormDataObject<BaseZodSchemaFromNestedType<T>>, Ref<input<T>>] {
    const valueObject: Ref<input<T>> = ref(values ? JSON.parse(JSON.stringify(values)) : {})

    const {
        convertNullToUndefined = false,
    } = options ?? {}

    if (convertNullToUndefined) {
        const convertNullToUndefinedDeep = (obj: Record<string, unknown>) => {
            for (const key in obj) {
                if (obj[key] === null) obj[key] = undefined
                if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
                    convertNullToUndefinedDeep(obj[key] as Record<string, unknown>)
                }
            }
        }

        convertNullToUndefinedDeep(valueObject.value)
    }

    function _generateFormDataObject(schema: T, valueObjParent: MaybeRefOrGetter<input<T>>, pathPrefix?: string): FormDataObject<T> {
        const path = pathPrefix ? `${pathPrefix}.` : ''

        const localValueObjParent = toValue(valueObjParent)

        return Object.fromEntries(
            Object.entries(schema).map(([key, _v]) => {
                const value = _v as SupportedZodTypes

                if (isZodObject(value)) {
                    const schema = getBaseZodSchema(value)

                    localValueObjParent[key] = localValueObjParent[key] ?? {}

                    if (!schema) return [key, {}]
                    return [key, _generateFormDataObject(schema as unknown as T, localValueObjParent[key], `${path}${key}`)]
                }

                const base = buildFormFieldObjectBase(value)

                localValueObjParent[key] = localValueObjParent[key] ?? base.__v

                const formFieldObject = {
                    __f: `${path}${key}`,
                    __r: base.__r,
                }

                return [key, Object.defineProperty(formFieldObject, '__v', {
                    get: () => localValueObjParent[key],
                    set: (val) => {
                        localValueObjParent[key] = val
                    },
                })]
            })
        ) as FormDataObject<BaseZodSchemaFromNestedType<T>>

    }

    const normalizedSchema = getBaseZodSchema(schema as any)
    if (!normalizedSchema) throw new Error('[generateFormDataObject]: Could not normalize schema.')
    return [_generateFormDataObject(normalizedSchema, valueObject), valueObject]
}

export function mergeFormValues<T extends ZodSchema>(_formValues: MaybeRefOrGetter<input<T>>, values: Partial<input<T>> | null | undefined, options?: Partial<{ fallbackToCurrentValues: boolean }>) {
    const formValues: Ref<input<T>> = toRef(_formValues)
    for (const key in formValues.value) {
        if (typeof formValues.value[key] === 'object' && !Array.isArray(formValues.value[key]) && formValues.value[key] !== null) {
            mergeFormValues(() => formValues.value[key], values?.[key], options)
            continue
        }
        formValues.value[key] = values?.[key] ?? (options?.fallbackToCurrentValues !== false ? formValues.value[key] : values?.[key])
    }
}

export function getFormFieldObject<T extends FormFieldObject<any>, D extends FormDataObject<any>>(key: string | null | undefined, formData: D): T | null {
    if (!key) return null
    const keys = key.split('.')
    let current = formData as any

    for (const k of keys) {
        if (!current[k]) return null
        current = current[k]
    }

    return current as T
}

export function setFormFieldObjectValue<T extends FormFieldObject<any>>(field: T | ModelRef<T | undefined> | undefined, value: T['__v']): FormEvent<T> | null {
    if (!field) return null

    if (isRef(field)) {
        if (!field.value) return null

        field.value.__v = value
        return {
            type: 'change',
            __f: field.value.__f,
        }
    }

    field.__v = value

    return {
        type: 'change',
        __f: field.__f,
    }
}
